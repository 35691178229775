/* njdi.scss */

/* Note: Bulma breakpoints are set at mobile (up to 768px), tablet (769px and up), desktop (1024px and up), widescreen (1216px and up), and fullhd (1408px and up). */

.hide {display:none;}
.pageloader {background-color:$blue-grey !important;}
.infraloader {background-color:$light-blue-grey !important;}

.section .title-divider {background:$light-blue-grey;}

.section.section-feature-dark {background-color: $blue-grey;}
.section.section-feature-dark h2 {color:#fff;}
.section.section-feature-dark .title-divider {background-color: $light-blue-grey;}
.section.section-feature-dark p.subheading {margin:0;padding:0;}
.section.section-feature-dark p.sub-subheading {margin:0;padding:1.5em 0 0 0;text-align:left;font-size:1.1em;}
.section.section-feature-dark p, .section.section-feature-dark ul li, section.section-feature-dark strong {color:#fff;}
.section.section-feature-dark a, .section.section-feature-dark p.subheading a {color:$light-blue-grey;}
.section.section-feature-dark a:hover {color: #fff;}
.section.section-feature-dark .flex-card p, .section.section-feature-dark .flex-card ul li, .section.section-feature-dark .flex-card strong {color:$blue-grey;}
.section.section-feature-dark .flex-card a {font-weight:bold;color:$light-blue-grey;}
.section.section-feature-dark .flex-card a:hover {color:$light-blue-grey;}

.centered-title p.subheading {margin-bottom:2em;max-width:150vh;}
.centered-title p.subheading a {color:$blue-grey;}
.centered-title p.subheading a:hover {color:$light-blue-grey;}

.button.is-primary {background:$blue-grey;}
.button.is-primary:hover {background:$light-blue-grey;}

.navbar-wrapper .navbar-brand .navbar-presents {display:none;}
.navbar-wrapper.navbar-faded .navbar-brand .navbar-presents {display:block;}
.navbar-wrapper.navbar-faded #navbar-donate {background:$blue-grey;}
.navbar-wrapper.navbar-faded #navbar-donate:hover {color:#fff;}
 
.hero-body h1 {text-shadow: 2px 4px 3px rgba(0,0,0,0.3);}
.hero-body h2 {text-shadow: 1px 3px 2px rgba(0,0,0,0.3);line-height:1.6em;}
.hero-body #goToSearch {margin-top:2em;box-shadow: 1px 3px 2px rgba(0,0,0,0.3);}
.hero-foot .partner-tabs ul li {margin:0 0.4em 3em 0.4em;font-size:1.2em;font-weight:bold;color:#fff;}
.hero-foot .partner-tabs ul li a {padding:0;border:0;}
.hero-foot .partner-tabs ul li img.rtr-logo {height:50px !important;}
.hero-foot .partner-tabs ul li img.partner-logo {height:40px !important;}

#search input.search-age, #search input.search-year {max-width:50px;}
#search .select select {margin-right:0.5em;float:left;}
#search .note {font-weight:normal;color:$blue-grey;}
#search #tips-and-tricksm #search #tips-and-tricks h3, #search #tips-and-tricks ul li, #search #tips-and-tricks strong {color:$blue-grey !important;}
#search #tips-and-tricks h3 {margin-left:10px;}
@media only screen and (max-width: 1024px) {
	#search #tips-and-tricks {font-size:0.8em;}
}
@media only screen and (min-width: 1024px) {
	#search #tips-and-tricks {font-size:0.9em;}
}
@media only screen and (min-width: 1216px) {
	#search #tips-and-tricks {font-size:0.9em;}
}

.loading #spinner {background:url(../images/loading_spinner.svg) no-repeat center center;background-size: contain;min-height:300px;}
#results.loading p, #results.loading table {display:none;}

#results .table.responsive-table tr th {background:$blue-grey; color:#fff !important;}
#results .table.responsive-table tr:first-child {border-top:1px solid #ddd;background:transparent !important;color: #333 !important;}

@media only screen and (max-width: 1024px) {
	#results .table tr th {padding:5px;font-size:0.8em;}
	#results .table tr td {padding:5px;font-size:0.8em;}
}
@media only screen and (min-width: 1024px) {
	#results .table tr th {padding:8px;white-space:nowrap;font-size:0.9em;}
	#results .table tr td {padding:5px 8px;white-space:nowrap;font-size:0.9em;} 
}
@media only screen and (min-width: 1216px) {
	#results .table tr th {white-space:nowrap;}
	#results .table tr td {white-space:nowrap;}
}
#results .table.responsive-table tbody tr:hover {background-color:$grey-white !important;}
#results .table.responsive-table tr td.no-close-results {padding:20px;}
#results #noResults {text-align:center;font-size:1.4em !important;}

#browse .columns {margin-bottom:60px;}
#browse i.fa {font-size:5em;color:$blue-grey;}
#browse .section-feature-description p {margin-bottom:1em;}
#browse .section-feature-description ul {margin:0;padding:0;list-style-type:none;}
#browse .section-feature-description ul li {margin:0 0.5em 0 0;padding:0;list-style-type:none;font-weight:bold;}
#browse .section-feature-description ul li a {font-weight:normal;}
#browse .has-text-right .section-feature-description ul {text-align:right;float:right;}


#newsletter label {font-weight:bold;}
#newsletter label.checkbox {font-weight:normal;}
#newsletter p.note {font-size:0.8em;}
#newsletter ul {margin-top:1em;padding-left:20px;}
#newsletter .display_archive {margin-top:1em;font-size:0.8em;max-height:275px;overflow-y:scroll;}
#newsletter .campaign {margin-bottom:0.5em;}

#donate h2 i {font-size:0.7em !important;}
#donate p.subheading {margin-bottom:0;padding-bottom:0;}

.footer #twitter-widget-holder {max-height:340px;overflow-y:scroll;}
.footer .footer-header h3 a {color:#fff;}
.footer .footer-header h3 a:hover {color:$light-blue-grey;}